<template>
  <div class="container-fluid px-0 py-2">
    <div class="col-12">
      <div class="p-dash-cliente-master">
        <div class="col-12 col-md-12 col-lg-10 mx-auto">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-6 px-0 py-2 aos-init"
                  data-aos="fade"
                  data-aos-delay="100"
                >
                  <router-link :to="{ name: 'KitWelcome' }">
                    <img
                      src="~@/assets/img/botao-voltar.png"
                      class="aos-init"
                      height="35"
                      data-aos="fade"
                      data-aos-delay="300"
                      alt="Qsuíte"
                    />
                  </router-link>
                </div>
                <div
                  class="col-6 px-0 py-2 text-right aos-init"
                  data-aos="fade"
                  data-aos-delay="100"
                >
                  <img
                    src="~@/assets/img/hub_/hub-logo.svg"
                    class="img-fluid"
                    title="Logo"
                    style="max-height: 40px"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-10 mx-auto px-0 order-0">
              <div class="col-12">
                <div class="row">
                  <div
                    class="col-12 col-md-7 col-lg-6 p-0 py-2 p-sm-3 order-2 order-sm-1 d-none d-md-block align-self-center aos-init"
                    data-aos="fade"
                    data-aos-delay="200"
                  >
                    <div class="mx-auto w-100">
                      <img
                        :src="unity.tipology ? unity.tipology.image : null"
                        class="img-fluid"
                        alt="Qsuíte"
                        style="border-radius: 18px"
                      />
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-5 col-lg-5 mx-auto p-0 order-1 order-sm-2 align-self-center aos-init"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <form novalidate @submit.prevent="save">
                      <div class="col-12 text-left pb-4 px-0">
                        <span class="font-18 font-b-5 color-6">Olá,</span>
                        <span class="font-22 font-b-3 color-6"
                          >{{ user.first_name }}!</span
                        >
                      </div>
                      <div class="col-12 col-md-9 col-lg-8 text-left px-0">
                        <div class="w-100" style="margin-bottom: 20px">
                          <div class="form-group mb-0">
                            <label>Empreendimento</label>
                            <b-form-select
                              v-model="order.enterprise_id"
                              :options="enterprises"
                              v-validate="'required'"
                              name="enterprise"
                              :class="{
                                'is-invalid': errors.has('enterprise'),
                              }"
                            ></b-form-select>
                          </div>
                        </div>
                        <div
                          class="w-100"
                          style="
                            border-bottom: 2px var(--color) solid;
                            margin-bottom: 20px;
                            border-radius: 0 0 15px 15px !important;
                          "
                        >
                          <div class="form-group mb-0">
                            <label>Localizamos sua unidade</label>
                            <b-form-select
                              v-model="order.unity_id"
                              :options="unities"
                              v-validate="'required'"
                              name="unity"
                              :class="{ 'is-invalid': errors.has('unity') }"
                            ></b-form-select>
                          </div>
                        </div>
                      </div>
                      <div class="w-100 text-left">
                        <div class="col-12 px-0">
                          <h2
                            class="mb-1 font-20 font-b-3 pt-0"
                            style="color: #8d8d8d"
                          >
                            UNIDADE:
                            <span
                              class="font-32 font-b-5"
                              style="color: var(--color)"
                              >{{ unity.number }}</span
                            >
                          </h2>
                        </div>
                        <div class="w-100 mb-2">
                          <p
                            class="font-b-3 font-20 mb-2"
                            style="color: #8d8d8d; line-height: 25px"
                          >
                            {{ unity.tipology ? unity.tipology.name : null }}
                          </p>
                          <span style="color: #8d8d8d">{{
                            unity.tipology ? unity.tipology.description : null
                          }}</span>
                        </div>
                        <div class="col-12 d-md-none d-block p-0">
                          <div class="d-flex mx-auto w-100">
                            <img
                              :src="
                                unity.tipology ? unity.tipology.image : null
                              "
                              class="img-fluid"
                              alt="Qsuíte"
                              style="border-radius: 18px"
                            />
                          </div>
                        </div>
                        <div class="w-100 text-center text-sm-left">
                          <button
                            type="submit"
                            class="btn btn-info mt-3 mt-sm-1 pulse-mvp py-2 px-4"
                            style="background-color: var(--color)"
                          >
                            <span style="font-size: 16px">Confirmar</span
                            ><img
                              src="~@/assets/img/hub_/ico-touch-pad-w.png"
                              height="30"
                              class="ml-2"
                              alt="Qsuíte"
                            />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <BaseClientKitConditions />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserService from "@/services/resources/UserService";
const service = UserService.build();

export default {
  data() {
    return {
      enterprises: [],
      unities: [],
      unitiesByEnterprise: [],
      order: {
        enterprise_id: null,
        unity_id: null,
        tipology_id: null,
      },
      unity: {
        number: null,
        tipology: {
          name: null,
          description: null,
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },
  watch: {
    "order.unity_id"() {
      this.selectUnity();
    },
    "order.enterprise_id"() {
      this.selectEnterprise();
    },
  },
  methods: {
    selectEnterprise() {
      this.unities = [{ value: null, text: "Selecione" }];
      this.unitiesByEnterprise.forEach((element) => {
        if (element.unity.enterprise_id == this.order.enterprise_id) {
          this.unities.push({
            text: element.text,
            value: element.value,
            unity: element.unity,
          });
        }
      });

      if (this.unities.length >= 2) {
        this.order.unity_id = this.unities[1].value;
        this.order.tipology_id = this.unities[1].unity.tipology_id;
      }
    },
    selectUnity() {
      this.unities.forEach((element) => {
        if (
          element.value == this.order.unity_id &&
          element.unity.enterprise_id == this.order.enterprise_id
        ) {
          this.unity = element.unity;
          this.order.tipology_id = element.unity.tipology_id;
        }
      });
    },
    fetchUser() {
      let data = {
        id: this.user.id,
      };

      service
        .read(data)
        .then((resp) => {
          this.getEnterprises(resp.enterprises);
          this.getUnities(resp.unities);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEnterprises(enterprises) {
      this.enterprises = [{ value: null, text: "Selecione" }];

      enterprises.forEach((element) => {
        this.enterprises.push({
          text: element.enterprise.name,
          value: element.enterprise_id,
        });
      });

      if (this.enterprises.length >= 2) {
        this.order.enterprise_id = this.enterprises[1].value;
      }
    },
    getUnities(unities) {
      this.unities = [{ value: null, text: "Selecione" }];

      unities.forEach((element) => {
        this.unitiesByEnterprise.push({
          text: element.unity.number,
          value: element.unity_id,
          unity: element.unity,
        });
      });
      if (this.unities.length >= 2) {
        this.order.unity_id = this.unitiesByEnterprise[1].value;
        this.order.tipology_id = this.unitiesByEnterprise[1].unity.tipology_id;
      }
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let order = {
            unity_id: this.order.unity_id,
            enterprise_id: this.order.enterprise_id,
            tipology_id: this.order.tipology_id,
          };
          localStorage.order = JSON.stringify(order);
          this.$router.push({
            name: "KitProduct",
            params: order,
          });
        }
      });
    },
  },
  mounted() {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 4932490, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    this.fetchUser();
  },
};
</script>
